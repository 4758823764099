"use strict";

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _colors = _interopRequireDefault(require("./theme/colors"));

var _shadows = _interopRequireWildcard(require("./theme/shadows"));

var _measures = require("./theme/measures");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = {
  fontFamily: 'Rubik',
  breakpoints: Array.from(_measures.BREAKPOINTS.values()),
  breakpointNames: Array.from(_measures.BREAKPOINTS.keys()),
  space: [0, 4, 10, 14, 20, 25, 80],
  fontSizes: Array.from(_measures.FONT_SIZE.values()),
  fontWeights: Array.from(_measures.FONT_WEIGHT.values()),
  letterSpacing: Array.from(_measures.LETTER_SPACING.values()),
  buttonHeight: Array.from(_measures.BUTTON_HEIGHT.values()),
  iconSize: Array.from(_measures.ICON_SIZE.values()),
  defaultIcon: 'question-circle',
  shadowOpacity: _shadows.shadowOpacity,
  shadows: _shadows["default"],
  colors: _colors["default"]
};
exports["default"] = _default;