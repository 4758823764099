"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  white: '#ffffff',
  pink: '#ff2b7f',
  blue: '#1fb6ff',
  red: '#ff4949',
  green: '#13ce66',
  yellow: '#ffc82c',
  orange: '#FF7849',
  purple: '#7E5BEF',
  grey: '#1D1919',
  disabled: '#8492a6',
  lightGrey: '#e0e6ed',
  dark: '#3c4858',
  smoke: '#E0E6ED',
  darkSmoke: '#D3DCE6',
  extraDarkSmoke: '#C0CCDA',
  snow: '#F9FAFC',
  darkSnow: '#EFF2F7',
  extraDarkSnow: '#E5E9F2'
};
exports["default"] = _default;